<template>
    <div class="notice_detail">
        <Header/>
        <div class="box">

            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/notice' }">通知公告</el-breadcrumb-item>
                    <el-breadcrumb-item>通知公告详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="content">

                <div class="body">
                    <div class="title">
                        {{text.title}}
                    </div>
                    <div class="time">
                        <div>发布时间：</div>
                        <div>{{text.createTime}}</div>
                        <div style="margin-left: 5px">浏览次数：</div>
                        <div>{{text.viewCount}}</div>
                    </div>
                    <div class="text" v-html="text.content"></div>
                </div>

            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import { GetNotice,GetByKeyDetail } from '../api/list'
    export default {
        data() {
            return {
                uesr:'',
                text:''
            }
        },
        components: {
            Header,
            Footer,
        },
        created () {
            this.user=JSON.parse(window.sessionStorage.getItem("user"))
            if(this.$route.query.id==1){
                GetByKeyDetail().then(res=>{
                    this.text=res.data

                })
            }else{
                GetNotice(this.$route.query.id).then(res=>{
                    this.text=res.data
                })
            }
        },

        methods: {
            handelgo(i){
                this.$router.push('/'+i)
            }
        }
    }
</script>

<style scoped>
@import url(../assets/css/Notice_detail.css);
</style>
